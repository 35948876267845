/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Data Analytics Catch"), "\n", React.createElement(_components.h3, null, "Open Source Analytic tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/plausible/analytics"
  }, "Plausible Analytics")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/umami-software/umami"
  }, "umami")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/offen/offen"
  }, "offen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stats.udara.io/"
  })), "\n"), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://neptune.ai/blog/best-practices-for-data-science-project-workflows-and-file-organizations"
  }, "Best Practices for Data Science Projects Directory Structure - neptune")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://drivendata.github.io/cookiecutter-data-science/"
  }, "Cookiecutter Data Science - drivendata")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://towardsdatascience.com/structure-your-data-science-projects-6c6c8653c16a"
  }, "Project Structure - towardsdatascience")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
